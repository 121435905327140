@font-face {
    font-family: "Trap";
    src: url('./fonts/Trap-Black.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('./fonts/Trap-Bold.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('./fonts/Trap-ExtraBold.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('./fonts/Trap-Light.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('./fonts/Trap-Medium.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('./fonts/Trap-Regular.otf') format('opentype');
}

@font-face {
    font-family: "Trap";
    src: url('./fonts/Trap-SemiBold.otf') format('opentype');
}

html {
    font-family: 'Trap', sans-serif;
}
